<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      New Customer
    </hero-bar>
    <section class="section is-main-section">
      <form @submit.prevent="submit">
        <card-component title="Customer Details" icon="account">
            <div class="columns">
              <div class="column is-three-fifths">
                <b-field label="Customer Name" expanded>
                  <b-input
                    minlength="3"
                    v-model="customer.name"
                    name="customerName"
                    required
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Village">
                  <b-select
                    v-model="customer.villageCode"
                    placeholder="Select village"
                    required
                    expanded
                  >
                    <option
                      v-for="(village, index) in customerVillages"
                      :key="index"
                      :value="village.code"
                    >
                      {{ village.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Status">
                  <b-select
                    v-model="customer.statusCode"
                    placeholder="Select status"
                    required
                    expanded
                  >
                    <option
                      v-for="(status, index) in customerStatuses"
                      :key="index"
                      :value="status.code"
                    >
                      {{ status.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Address">
                  <b-input
                    minlength="3"
                    v-model="customer.address"
                    name="customerAddress"
                    required
                  />
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="Remarks">
                  <b-input
                    type="textarea"
                    v-model="customer.remarks"
                    name="customerRemarks"
                  />
                </b-field>
              </div>
            </div>
            <hr />
            <div class="columns">
              <div class="column">
                <b-field label="Contact Person">
                  <b-input
                    minlength="3"
                    v-model="customer.contactPerson"
                    name="customerContactPerson"
                    required
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Contact Number">
                  <b-input
                    minlength="8"
                    v-model="customer.contact"
                    name="customerContactNumber"
                    required
                  />
                </b-field>
              </div>
            </div>
        </card-component>

        <card-component title="Bins" icon="trash-can">
          <b-table :data="bins" v-if="bins.length" hoverable>
            <b-table-column field="label" label="Label" v-slot="props">
                {{ props.row.label }}
            </b-table-column>

            <b-table-column field="size" label="Size" v-slot="props">
                {{ binSizesMapping[props.row.sizeCode] }}
            </b-table-column>

            <b-table-column field="type" label="Type" v-slot="props">
                {{ binTypesMapping[props.row.typeCode] }}
            </b-table-column>

            <b-table-column field="service" label="Service" v-slot="props">
                {{ binServicesMapping[props.row.serviceCode] }}
            </b-table-column>

            <b-table-column field="action" label="Action" v-slot="props" centered>
                <b-field grouped position="is-centered">
                  <div class="control">
                    <b-button type="is-primary" size="is-small" @click="editBin(props.index)"
                      >Edit</b-button
                    >
                  </div>
                  <div class="control">
                    <b-button type="is-danger" size="is-small" @click="remove(props.index)"
                      >Remove</b-button
                    >
                  </div>
                </b-field>
            </b-table-column>
          </b-table>

          <div class="content has-text-grey has-text-centered" v-else>
            <p>
              <b-icon icon="playlist-remove" size="is-large" />
            </p>
            <p>No bin record. Click Add Bin to add a bin.</p>
          </div>

          <hr />

          <b-field grouped position="is-right">
            <div class="control">
              <b-button @click="exit"
                >Cancel</b-button
              >
            </div>
            <div class="control">
              <b-button type="is-primary is-outlined" @click="addBin"
                >Add Bin</b-button
              >
            </div>
            <div class="control">
              <b-button native-type="submit" type="is-primary"
                >Submit</b-button
              >
            </div>
          </b-field>
        </card-component>
      </form>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import customer from '@/services/customer'

import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import AddEditBin from '@/modals/AddEditBin'

export default {
  name: 'CreateCustomer',
  components: {
    TitleBar,
    HeroBar,
    CardComponent
  },
  data () {
    return {
      customer: {},
      bins: []
    }
  },
  methods: {
    submit () {
      this.createCustomer()
    },
    async createCustomer () {
      try {
        await customer.createCustomer({
          ...this.customer,
          bins: this.bins
        })
        this.$buefy.snackbar.open({
          message: 'Company created successfully',
          queue: false
        })
        this.$router.go(-1)
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    },
    addBin () {
      this.$buefy.modal.open({
        component: AddEditBin,
        parent: this,
        events: {
          saveBin: ({ bin, close }) => {
            this.bins.push(bin)
            this.$buefy.toast.open('Bin added successfully')
            close()
          }
        }
      })
    },
    editBin (idx) {
      this.$buefy.modal.open({
        component: AddEditBin,
        parent: this,
        props: {
          mode: 'edit',
          binData: JSON.parse(JSON.stringify(this.bins[idx]))
        },
        events: {
          saveBin: (bin) => {
            const tempCopy = this.bins
            tempCopy[idx] = bin
            this.bins = [...tempCopy]
            this.$buefy.toast.open('Bin updated successfully')
          }
        }
      })
    },
    remove (idx) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove bin?',
        onConfirm: () => {
          const tempCopy = [...this.bins]
          tempCopy.splice(idx, 1)
          this.bins = tempCopy
          this.$buefy.toast.open('Bin removed successfully')
        }
      })
    },
    exit () {
      // check if there is data added
      if (Object.values(this.customer).length || this.bins.length) {
        this.$buefy.dialog.confirm({
          message: 'Information will not be saved. Are you sure you want to exit?',
          onConfirm: () => {
            this.$router.go(-1)
          }
        })
      } else {
        this.$router.go(-1)
      }
    }
  },
  computed: {
    ...mapGetters('lookups', [
      'binTypesMapping',
      'binServicesMapping',
      'binSizesMapping',
      'customerStatuses',
      'customerVillages'
    ]),
    titleStack () {
      return ['Customers', 'New']
    }
  }
}
</script>
